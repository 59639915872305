.is-collapsible {
  display: block;
  height: 0;
  opacity: 0;
  will-change: height, opacity;

  transition: height 0.5s ease-out, opacity 0.6s ease-out !important;

  &.is-active {
    opacity: 1;
    height: auto;
    transition: opacity 0.4s ease-in, height 0.5s ease-in !important;
  }
}
