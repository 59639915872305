@import '../../styles/variables.scss';

.fa-bell.unread-badge {
  &:after {
    content: ' ';
    display: inline-flex;
    position: absolute;
    background: $red;
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.7rem;
    font-size: 0.7rem;
    top: 0.85rem;
    right: 0.85rem;
    text-align: center;
    border-radius: 99999px;
    color: $white;
    border: 1px solid $red;
  }
}

.navbar-item-notification {
  padding-right: 28px !important;

  &:hover {
    box-shadow: 2px 0 0 $primary inset;
    background-color: hsl(206deg, 70%, 98%);
  }

  &.is-read {
    background-color: hsla(0deg, 0%, 98%, 0.85);
  }

  &-status {
    width: 8px;

    &-unread {
      width: 8px;
      height: 8px;
      border-radius: $radius-rounded;
      background-color: $primary;
    }
  }

  .user-notification {
    &-title {
      max-width: 64ch;
    }
  }
}

.navbar-divider-notification {
  height: 1px !important;
}
