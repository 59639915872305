@import '../../styles/index.scss';

.navbar {
  min-height: $navbar-height !important;
  background: $primary !important;

  .has-rounded-corners {
    border-radius: $radius;
  }

  .navbar-brand {
    a {
      &.navbar-logo {
        text-decoration: none !important;
        color: $white;

        img {
          min-width: 100%;
          min-height: 100%;
        }
      }
    }

    .navbar-burger {
      color: $white;
    }
  }

  .navbar-item-login {
    user-select: none;

    .login-email {
      font-weight: 600;
    }
  }

  .navbar-link {
    color: $white;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: transparent !important;
      color: $white;
    }
  }

  .navbar-link:not(.is-arrowless):after {
    right: 1.5em;
    height: 0.425em;
    width: 0.425em;
    border-color: $white;
  }

  .navbar-item {
    .icon,
    .icon:only-child {
      margin-right: 4px;
    }
  }

  .navbar-item,
  .navbar-start {
    &.is-flex-grow {
      flex-grow: 1 !important;
    }
  }

  .navbar-item {
    &.has-flex-centered-content {
      justify-content: center !important;
    }
  }

  .navbar-center {
    width: 100%;
    position: absolute;
    top: 0;
    background: transparent;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-top-links {
    display: flex;
  }
}

@media screen and (max-width: 989px) {
  .navbar-item {
    figure {
      padding-top: 5px;
    }
  }

  .navbar-menu {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

@media screen and (min-width: 992px) {
  .navbar-item.has-dropdown:focus .navbar-link,
  .navbar-item.has-dropdown:hover .navbar-link,
  .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: $primary !important;
  }

  .navbar-dropdown.is-right {
    right: -4px !important;
  }
}
