@import '@yaireo/tagify/dist/tagify.css';

.scopes {
  --tag-bg: $primary;
  --tags-focus-border-color: #485fc7;
  --tags-hover-border-color: #485fc7;

  tags.tagify {
    &:hover,
    &.tagify--focus {
      box-shadow: 0 0 0 0.125em #485fc740 !important;
    }

    &.input.scopes-input {
      height: auto !important;
    }

    .tagify__input {
      overflow-x: hidden;
    }
  }

  input {
    &.scopes-control {
      width: 100%;
      height: 100%;
      color: transparent;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    &.input.scopes-input {
      height: auto !important;
    }
  }
}

.fio-tag {
  .tagify__dropdown__wrapper {
    border-color: #485fc7 !important;
  }
}
