html {
  text-rendering: auto;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
  display: inherit;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
