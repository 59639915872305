@import './variables.scss';

.circle {
  height: 10px;
  width: 10px;
  margin-top: 0.2rem;
  border-radius: 50%;

  background-color: $grey-light;

  &.is-success {
    background-color: $green;
  }

  &.is-primary {
    background-color: $primary;
  }

  &.is-info {
    background-color: $info;
  }

  &.is-warning {
    background-color: $warning;
  }

  &.is-danger {
    background-color: $red;
  }
}
