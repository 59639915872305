button,
.button {
  &.has-background-transparent {
    background: transparent !important;
    background-color: transparent !important;
  }

  &.is-height-auto {
    height: auto;
  }

  &.is-min-height-fit-content {
    min-height: fit-content;
  }

  &.has-no-box-shadow-on {
    &-focus {
      &:focus,
      &:focus:not(:active) {
        box-shadow: none;
      }
    }
  }

  &.is-color-inherit {
    &-hover {
      &:hover {
        color: inherit;
      }
    }
    &-focus {
      &:focus,
      &:focus-within {
        color: inherit;
      }
    }
    &-active {
      &:active {
        color: inherit;
      }
    }
  }

  &.is-empty,
  &.is-icon {
    margin: -4px;
    padding: 4px;
    border: none;
  }

  &.is-empty {
    background-color: none;
    background: transparent;

    &:active,
    &:hover,
    &:focus-within,
    &:focus {
      border: none;
      background-color: none;
      background: transparent;
    }
  }

  &.is-title-button {
    border: none;
    background-color: none;
    background: transparent;

    &.is-white {
      &:active,
      &:hover,
      &:focus-within,
      &:focus {
        border: none;
        background-color: none;
        background: transparent;
      }
    }

    &.is-primary {
      color: $primary;

      &:active,
      &:hover,
      &:focus-within,
      &:focus {
        border: none;
        background-color: none;
        background: transparent;
        color: $primary-dark;
      }
    }
  }
}
