@include touch {
  .is-coupon {
    .coupon-help {
      .coupon-desc {
        width: 100%;
      }
    }
  }
}

.is-coupon {
  .coupon-help {
    display: flex;
    justify-content: flex-start;

    .coupon-desc {
      width: 280px;
      text-align: right;
      margin-top: 8px;
      padding: 8px;
      border-radius: $radius;
      background-color: $white-ter;

      label,
      span {
        display: inline-block;
      }

      label {
        font-weight: 600;
      }

      .fee {
        width: 160px;
      }
    }
  }

  .valid-coupon {
    justify-content: flex-end;
    font-size: 0.85rem;
  }
}
