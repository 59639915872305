.control {
  &.is-success {
    input {
      border-color: $green;

      &:focus {
        box-shadow: 0 0 0 0.125em rgba($green, 0.25);
      }
    }
  }

  &.is-danger {
    input {
      border-color: $red;

      &:focus {
        box-shadow: 0 0 0 0.125em rgba($red, 0.25);
      }
    }
  }
}

.label {
  &.required {
    &:after {
      padding-left: 4px;
      content: '*';
    }
  }
}
