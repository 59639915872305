$drawer-padding: 1.25rem;

.drawer {
  display: block;
  visibility: hidden;
  will-change: visibility;

  transition: visibility 0s 0.3s;

  &-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: 99;
    opacity: 0;

    will-change: width, opacity;

    background-color: $grey-lighter;
    user-select: none;
  }

  &-card {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 0;
    max-width: 0;
    z-index: 100;
    overflow: auto;

    padding: $drawer-padding;

    background-color: $white;

    display: flex;
    flex-direction: column;

    transition:
      width 0.3s,
      max-width 0.3s ease-out 0.1s;
    will-change: width, max-width;

    -webkit-overflow-scrolling: touch;

    box-shadow: 0 4px 8px $grey-light;

    &-head {
      display: flex;

      &-title {
        font-size: 1.2rem;
        font-weight: bolder;
      }

      button.is-drawer-close {
        z-index: 101;
        position: absolute;
        right: $drawer-padding;
      }
    }

    &-content {
      scrollbar-color: $grey-light transparent;
      scrollbar-width: thin;

      position: relative;
      overflow-x: overlay;
      overflow-y: overlay;
      height: 100%;
      flex-grow: 1;
    }
  }

  &.is-active {
    visibility: visible;
    transition: visibility 0s;

    .drawer-background {
      width: 100%;
      opacity: 0.35;
    }

    .drawer-card {
      transition:
        width 0.3s,
        max-width 0.3s ease-in;
    }
  }
}

@include touch {
  .drawer {
    &.is-active {
      .drawer-card {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@include desktop {
  .drawer {
    &.is-active {
      .drawer-card {
        width: 75%;
        max-width: 75%;
      }
    }
  }
}

@include widescreen {
  .drawer {
    &.is-active {
      .drawer-card {
        width: 70%;
        max-width: 70%;
      }
    }
  }
}
