.app-box {
  &-title {
    text-transform: capitalize;
  }

  &.is-danger-zone {
    .box {
      border: 1px solid $danger;
    }
  }

  &.is-relative {
    .is-absolute-container {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .is-boxed {
      top: 0;
      right: 0;
    }
  }

  hr {
    &.app-box {
      margin: 0 -1rem;
      height: 1px;
    }
  }
}

@include touch {
  .app-box {
    margin-right: 1rem;
    margin-left: 1rem;

    &.is-relative {
      .is-absolute-container {
        position: relative;
        top: 0;
        right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
