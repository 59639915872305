dl {
  &.dl-horizontal {
    dt {
      width: 160px;
      display: block;
      float: left;
    }
  }

  dt {
    font-weight: 700;
  }

  dd,
  dt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &.wrap {
      white-space: normal;
    }
  }

  &.dl-overview {
    dt {
      font-size: 0.8rem;
      font-weight: 700;
      color: $grey-500;
      margin-bottom: 0 !important;
      text-transform: uppercase;
    }

    dd {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  dl {
    &.dl-horizontal {
      dt {
        width: 100%;
        float: none;
        margin-bottom: 0;
      }

      dd:last-child {
        margin-bottom: 4px;
      }
    }
  }
}
