.title {
  &.is-flex {
    .icon {
      &.is-button {
        align-self: center;
      }
    }
  }
}

.icons {
  > .icon {
    &:not(:last-child) {
      margin-right: 0.35rem;
    }
  }
}

.icon {
  &.is-button {
    cursor: pointer;

    &.is-small {
      font-size: 1rem;
    }
  }

  &.is-x-large {
    height: 5rem;
    width: 5rem;
  }

  &.is-xx-large {
    height: 7rem;
    width: 7rem;
  }

  &.is-xxx-large {
    height: 9rem;
    width: 9rem;
  }
}
