.factory-create-overlay {
  background-color: rgba(245, 245, 245, 0.98);
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-content {
    text-align: center;
    vertical-align: middle;
    font-size: 1.5rem;

    .icon {
      .fa-og {
        --fa-primary-color: #37485c;
      }
    }

    small {
      font-size: 0.9rem;
    }
  }
}
