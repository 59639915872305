td,
th {
  &.no-stretch {
    width: 1px;
    white-space: nowrap;
  }
}

table.table {
  &.table-devices,
  &.table-target-apps,
  &.table-tuf-targets,
  &.table-tokens,
  &.table-targets,
  &.table-runs,
  &.table-tags,
  &.table-waves,
  &.table-wave-groups,
  &.table-run-tests {
    text-align: left;

    th {
      border-bottom-width: 2px;
    }

    > thead {
      > tr th {
        font-size: 0.8rem;
        color: $grey-600;
      }
    }

    > tbody {
      color: $black;
      > tr td {
        vertical-align: middle;
      }
    }
  }

  &.table-tokens {
    > thead {
      > tr th {
        &:last-child {
          text-align: right;
        }
      }
    }

    > tbody {
      > tr td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &.table-targets {
    > thead {
      > tr th {
        &:first-child {
          text-align: right;
        }
      }
    }

    tbody {
      > tr {
        &:hover {
          cursor: pointer;
        }

        > td {
          &:first-child {
            text-align: right;
          }
        }
      }
    }
  }

  &.table-runs {
    > thead {
      > tr th {
        &:first-child {
          width: 1rem;
          max-width: 1rem;
        }
      }
    }

    th {
      border-bottom: none;
    }

    tbody {
      > tr {
        &:not(.is-expanded):hover {
          cursor: pointer;
        }

        > td {
          border-bottom: none;

          &:nth-child(2) {
            white-space: nowrap;
          }
          &:nth-child(3) {
            width: 4rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &.table-tags {
    text-align: right;

    td,
    th {
      border-bottom: none;
    }

    > thead {
      > tr th {
        &:first-child {
          width: 1rem;
          max-width: 1rem;
        }

        &:nth-child(2) {
          text-align: left;
        }
      }
    }

    > tbody {
      > tr {
        &:not(.is-expanded):hover {
          cursor: pointer;
        }

        td {
          &:nth-child(2) {
            text-align: left;
          }
        }
      }
    }
  }

  &.table-wave-groups {
    > tbody {
      > tr {
        &:nth-last-child(2) {
          border-bottom-width: 2px;
        }
      }
    }
  }
}
