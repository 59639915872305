.wave-details,
.device-details {
  font-size: 14px;

  dl {
    > dt {
      color: $grey-dark;
    }
    > dd {
      font-size: 1.05em;
    }
  }
}

.device-details {
  &-public-key {
    white-space: pre-line;
  }
}
