@import './variables.scss';

@import '~bulma-switch/src/sass/index.sass';
@import '~bulma-timeline/src/sass/index.sass';
@import '~@creativebulma/bulma-divider';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';
@import 'animate.css';
@import 'highlight.js/scss/github.scss';

@import '~placeholder-loading/src/scss/placeholder-loading.scss';
@import '~@foundriesio/cookie-consent/styles/cookie-consent.scss';

@import '~@awesome.me/kit-8b90e42ad7/icons/scss/fontawesome.scss';
@import '~@awesome.me/kit-8b90e42ad7/icons/scss/custom-icons.scss';
@import '~@awesome.me/kit-8b90e42ad7/icons/scss/brands.scss';
@import '~@awesome.me/kit-8b90e42ad7/icons/scss/duotone.scss';
@import '~@awesome.me/kit-8b90e42ad7/icons/scss/light.scss';
@import '~@awesome.me/kit-8b90e42ad7/icons/scss/regular.scss';
@import '~@awesome.me/kit-8b90e42ad7/icons/scss/solid.scss';
@import '~@awesome.me/kit-8b90e42ad7/icons/scss/thin.scss';

:root {
  --line-height-heading-xl: 110%;
  --line-height-heading: 120%;
  --letter-spacing: 1%;
  --breadcrumbs-min-height: 48px;
}

@each $name, $color in $foundries-colors {
  .has-text-#{$name} {
    color: $color !important;
  }

  .has-background-#{$name} {
    background-color: $color !important;
  }
}

@each $shade, $color in $grey-shades {
  .has-text-grey-#{$shade} {
    color: $color !important;
  }

  .has-background-grey-#{$shade} {
    background-color: $color !important;
  }
}

@each $dimension, $size in $text-dimensions {
  .is-font-size-#{$dimension} {
    font-size: $size !important;
  }
}

.is-padding-less-table {
  tbody > tr > td,
  thead > tr > th {
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
}

.ml {
  &-tiny {
    margin-left: 2px !important;
  }
}

.mt- {
  &8 {
    margin-top: 4rem !important;
  }
}

hr {
  margin: 1rem 0;
}

h1,
h1.title {
  // color: $black;
  font-weight: lighter;
}

h2,
h2.title {
  // color: $black;
  font-weight: lighter;
}

h3,
h3.title {
  // color: $black;
  font-weight: normal;
}

// h4,
// h4.title {
//   color: $black;
// }

// h5,
// h5.title {
//   color: $black;
// }

// h6,
// h6.title {
//   color: $black;
// }

.title {
  color: $black;

  .is-title-link {
    color: inherit;
  }
}

label.required {
  &:after {
    content: '*';
    padding-left: 4px;
  }
}

.is-centered-wrapper,
.is-vertical-centered-wrapper {
  display: flex;
  align-items: center;
}

.is-fullheight {
  height: 100% !important;
}

.is-vertical-centered-wrapper {
  height: 100%;
}

.has-text-justified {
  text-align: justify;
}

a {
  &[disabled] {
    text-decoration: none;
    cursor: not-allowed;
    color: $grey;
    opacity: 0.75;

    &:hover,
    &:focus {
      color: $grey;
    }
  }
}

.is {
  &-normal {
    text-transform: none !important;
  }

  &-variant-numeric {
    font-variant-numeric: tabular-nums slashed-zero !important;
  }

  &-white-space {
    &-normal {
      white-space: normal !important;
    }
  }

  &-relative {
    position: relative;
  }

  &-inline {
    &-flex {
      display: inline-flex !important;
    }
  }

  &-fullheight {
    height: 100% !important;
  }

  &-fullwidth {
    width: 100% !important;
  }

  &-pointer-events {
    &-none {
      pointer-events: none !important;
    }
    &-all {
      pointer-events: all !important;
    }
  }

  &-grid {
    display: grid !important;
  }

  &-block {
    display: block !important;
  }

  &-overflow {
    &-x {
      &-hidden {
        overflow-x: hidden !important;
      }
      &-auto {
        overflow-x: auto !important;
      }
    }

    &-y {
      &-auto {
        overflow-y: auto !important;
      }
      &-hidden {
        overflow-y: hidden !important;
      }
    }
  }

  &-list-style {
    &-none {
      list-style: none !important;
    }
  }
}

.has {
  &-border {
    &-grey {
      border: 1px solid $grey;
    }

    &-grey-light {
      border: 1px solid $grey-light;
    }

    &-grey-lighter {
      border: 1px solid $grey-lighter;
    }

    &-grey-lightest {
      border: 1px solid $grey-lightest;
    }

    &-radius {
      border-radius: $radius;
    }
  }

  &-font-size {
    &-09-rem {
      font-size: 0.9rem !important;
    }

    &-095-rem {
      font-size: 0.95rem !important;
    }

    &-08-rem {
      font-size: 0.8rem !important;
    }

    &-1-1-rem {
      font-size: 1.1rem !important;
    }
  }

  &-text-decoration {
    &-underline {
      text-decoration: underline;
    }

    &-none {
      text-decoration: none !important;
    }
  }

  &-text-underline-position {
    &-below {
      text-underline-position: below !important;
    }
  }

  &-no-border {
    border: none !important;
  }

  &-cursor {
    &-pointer {
      cursor: pointer !important;
    }

    &-not-allowed {
      cursor: not-allowed !important;
    }
  }

  &-letter-spacing {
    &-half {
      letter-spacing: 0.5px;
    }
  }

  &-gap {
    &-1 {
      gap: 0.25rem;
    }

    &-2 {
      gap: 0.5rem;
    }

    &-3 {
      gap: 0.75rem;
    }

    &-4 {
      gap: 1rem;
    }

    &-5 {
      gap: 1.5rem;
    }
  }

  &-column-gap {
    &-6 {
      column-gap: 2rem;
    }

    &-5 {
      column-gap: 1.5rem;
    }

    &-4 {
      column-gap: 1rem;
    }

    &-2 {
      column-gap: 0.5rem;
    }

    &-1 {
      column-gap: 0.25rem;
    }
  }

  &-row-gap {
    &-6 {
      row-gap: 2rem;
    }

    &-5 {
      row-gap: 1.5rem;
    }

    &-4 {
      row-gap: 1rem;
    }

    &-2 {
      row-gap: 0.5rem;
    }

    &-1 {
      row-gap: 0.25rem;
    }
  }

  &-outline {
    &-none {
      outline: none !important;
    }
  }
}

.pr {
  &-8 {
    padding-right: 4rem !important;
  }
  &-10 {
    padding-right: 6rem !important;
  }
}

kbd {
  padding: 1px;
}

.select:not(.is-multiple):not(.is-loading) {
  &:hover {
    &:after {
      color: $link-hover !important;
    }
  }
  &:after {
    border: none !important;
    border-radius: none !important;
    margin-top: -0.55rem !important;
    box-sizing: inherit !important;
    font-family: 'Font Awesome\ 6 Pro' !important;
    font-weight: 900 !important;
    content: '\f107' !important;
    transform: none !important;
    border-color: inherit !important;
    color: inherit !important;
  }
}

details {
  summary {
    display: flex;
    align-items: center;

    cursor: pointer;

    &::marker {
      content: '';
    }

    &::after {
      content: '\f105';
      font-family: 'Font Awesome\ 6 Pro' !important;
      margin-left: 4px;
    }

    > * {
      display: inline;
    }
  }

  &[open] {
    summary {
      &::after {
        content: '\f107';
        font-family: 'Font Awesome\ 6 Pro' !important;
      }
    }
  }
}

@import './resets.scss';
@import './form.scss';
@import './message.scss';
@import './icon.scss';
@import './box.scss';
@import './circle.scss';
@import './dl.scss';
@import './overlay.scss';
@import './credit-card.scss';
@import './coupon.scss';
@import './button.scss';
@import './notification.scss';
@import './scopes.scss';
@import './tables.scss';
@import './level.scss';
@import './drawer.scss';
@import './details.scss';
@import './margin.scss';
@import './collapsible.scss';
@import './artifact-tree.scss';

// This needs to be at the bottom or it screws up the CSS bundle.
@import '~bulma-o-steps/index.sass';
