.credit-card {
  input.control {
    opacity: 0;
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
  }

  div.control {
    > div:first-child {
      &.StripeElement {
        background-color: $white;
        border: 1px solid rgb(219, 219, 219);
        border-radius: $radius;
        padding: 8px 10px 6px 10px;
        height: 40px;

        &:hover {
          border-color: #b5b5b5;
        }

        &.StripeElement--focus {
          border-color: #485fc7;
          box-shadow: 0 0 0 0.125em #485fc740 !important;
          outline: none;
        }
      }
    }

    &.is-danger {
      > div:first-child {
        &.StripeElement {
          color: $danger;
          border-color: $danger;

          &.StripeElement--focus {
            border-color: $danger;
            box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25) !important;
            outline: none;
          }
        }
      }
    }
  }
}
