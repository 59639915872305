@import '../../styles/variables.scss';

.doc-search-box {
  scrollbar-width: thin;
  scrollbar-color: $grey-light transparent;

  max-height: 300px;

  section.doc-search-section {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:focus,
      &:hover {
        background-color: $primary-light;
      }

      &:focus {
        border-color: $primary;
      }

      &.is-selected {
        background-color: $primary-light;
        border-color: $primary;
      }
    }
  }
}
