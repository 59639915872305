div.artifacts {
  ul {
    &.tree {
      padding: 0;
      user-select: none;
      list-style: none;
      margin-left: 16px;

      &.root {
        margin-left: 0;
      }

      a {
        color: inherit;
        text-decoration: none !important;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    &.subtree {
      padding-left: 8px;
    }

    li {
      & label {
        cursor: pointer;
      }

      &.directory {
        & > ul {
          display: none;
        }
      }

      &.file {
        margin-top: 4px;
        display: block;
        position: inherit;

        &:last-child {
          margin-bottom: 4px;
        }

        &:hover {
          background-color: $primary-light;
          margin-left: -2px;
          margin-right: -2px;
          padding-left: 2px;
          padding-right: 2px;
        }
      }

      & input + label {
        margin: 0;
      }

      input[type='checkbox'] {
        display: none;

        & + label:before {
          content: '\f07b';
        }

        &:checked + label:before {
          content: '\f07c';
        }

        &:checked &:hover {
          font-style: italic;
        }
      }
    }

    li.file:before,
    label:before {
      font-family: 'Font Awesome 6 Pro';
      font-weight: 300;
      font-style: normal;
      display: inline-block;
      text-decoration: inherit;
      padding-right: 4px;
    }

    input:checked ~ ul {
      display: block;
    }
  }
}
