.level {
  &-target {
    font-size: 0.9rem;

    &-start {
      justify-content: flex-start;

      > .level-item {
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }

    dl {
      dt {
        font-size: 0.94em;
      }
    }

    .level-item {
      &-small {
        flex-basis: 128px;
      }
    }

    &-hashes,
    &-apps-tags {
      flex-basis: 100% !important;

      .level-item {
        flex-basis: 48% !important;
      }
    }
  }
}

@include touch {
  .level {
    &-target {
      &-apps-tags {
        .level-item {
          flex-basis: 100% !important;
        }
      }
    }
  }
}
